import * as Sentry from '@sentry/react';

interface UserProfile {
  id: number;
  username: string;
  email: string;
}

const isProd = process.env.ENV_TITLE === 'Production';

let isSentrySet = false;

export const initSentry = () => {
  if (!isSentrySet && process.env.SENTRY_DSN) {
    isSentrySet = true;

    const options: Sentry.BrowserOptions = {
      dsn: process.env.SENTRY_DSN,
      environment: process.env.ENV_TITLE,
      integrations: [Sentry.browserTracingIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0,
      // URLS distributed tracing is enabled for
      tracePropagationTargets: ['localhost', process.env.URL_PAGE as string],
    };

    if (isProd) {
      // set up Sentry Replay in prod only
      // @ts-ignore, cuz it is never undefined
      options.integrations.push(
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      );
      options.replaysSessionSampleRate = 0.1;
      options.replaysOnErrorSampleRate = 1.0;
    }
    // Sentry Setup
    Sentry.init(options);
  }
};

let currentUser = '';

export const setSentryUserProfile = (user: UserProfile): void => {
  if (process.env.SENTRY_DSN && (!currentUser || user.email !== currentUser)) {
    currentUser = user.email;
    Sentry.setUser({
      ...user,
      ip_address: '{{auto}}',
    });
  }
};

export const sendSentryError = (error: any, ...opt: any) => {
  if (process.env.SENTRY_DSN && isSentrySet) {
    opt.forEach((data: any, i: number) => {
      const context = `Extra Context ${i}`;
      if (typeof data === 'object') {
        Sentry.setContext(context, data);
      } else {
        const val = {
          [`Context ${i}`]: data,
        };
        Sentry.setContext(context, val);
      }
    });
    Sentry.captureException(error);
  }
};
